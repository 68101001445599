body {
    background-color: #000222;
}
.header-title {
    -webkit-flex: 1 1;
            flex: 1 1;
    #background-color: red;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #5adafd;
    font-weight: bold;
    font-size: xx-large;
}
.header-subtitle {
    -webkit-flex: 1 1;
            flex: 1 1;
    #background-color: red;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #d80200;
    font-weight: bold;
    font-size: large;
}
.header-logo {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; /* align horizontal */
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px;
}
.header-logo-small img {
    position: absolute;
    float:left;
    top: 12px;
    left: 12px;
    width: 60px;
    height: 60px;
}
.language-switch {
    position: absolute;
    top: 20px;
    right: 20px;

}
.context-text  {
    color: #5adafd;
    margin: 4.5em;
    font-weight: normal;
    font-size: large;
    line-height: 1.5em;
}
italic-font-style { font-style: italic; }

.ui .form {
    margin: 20px 20px 0px 20px;
}
.ui.form .field>label {
    color: #5adafd;
}
